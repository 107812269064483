@import '~ethos-design-system/src/components/Media/Media.scss';

$forestBorder: solid 1px var(--BrandForest);
$grayBorder: solid 1px var(--GrayStrokeAndDisabled--opaque);
$lightGreenBorder: solid 2px #dae7e6;

.chart {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px var(--GrayStrokeAndDisabled--opaque);

    &:first-child .column:first-child {
      border-top: none;
    }

    &:first-child {
      border-color: var(--GraySecondary--opaque);
    }

    &:not(:first-child) .column {
      padding: var(--Space-24) 0 20px;
    }

    &:first-child .column:nth-child(even) {
      border-radius: 8px 8px 0px 0px;
      border-top: $forestBorder;
      border-right: $forestBorder;
      border-left: $forestBorder;
    }

    &:first-child .column:nth-child(odd) {
      padding-top: var(--Space-6);

      @include for-tablet-and-up {
        padding-top: var(--Space-12);
      }

      @media (max-width: 374px) {
        padding-right: var(--Space-4);
        padding-left: var(--Space-4);
      }
    }

    &:last-child {
      border-bottom: 0;

      @include for-tablet-and-up {
        border-bottom: solid 1px var(--GrayStrokeAndDisabled--opaque);
      }

      .column:nth-child(even) {
        border-bottom: $forestBorder;

        @include for-tablet-and-up {
          border-bottom: 0;
        }
      }
    }

    .column {
      display: flex;
      flex-basis: 33%;
      padding: 0;
      border-bottom: none;

      @media (max-width: 374px) {
        flex-basis: 30%;
        &:first-child {
          flex-basis: 40%;
        }
      }

      .icon {
        padding-top: var(--Space-4);
      }

      .logo {
        width: 104px;
        max-width: 100%;

        @include for-tablet-and-up {
          width: 120px;
          padding: var(--Space-8) 0;
        }
      }

      &:first-child {
        padding-right: var(--Space-4);
      }

      &:nth-child(even) {
        background: var(--BrandMoss);
        border-right: $forestBorder;
        border-left: $forestBorder;

        @include for-tablet-and-up {
          flex-basis: 24%;
        }
      }

      &:nth-child(odd) {
        @include for-tablet-and-up {
          flex-basis: 38%;
        }
      }

      &:not(:first-child) {
        justify-content: center;
        text-align: center;
        align-items: center;
      }
    }
  }

  .mobile {
    @include for-tablet-and-up {
      display: none;
    }
  }

  .desktop {
    display: none;

    @include for-tablet-and-up {
      display: block;
    }
  }

  &.rebranded {
    .row {
      &:first-child .column:nth-child(even) {
        border-top: none;
        border-right: none;
        border-left: none;
      }
      &:last-child {
        .column:nth-child(even) {
          border-bottom: none;
        }
      }
      .column {
        &:nth-child(even) {
          background: #d9e7e6;
          border-right: none;
          border-left: none;
        }
      }
    }
  }

  &.northstar {
    max-width: 780px;
    width: 100%;
    border-top: $grayBorder;
    border-right: $grayBorder;
    border-left: $grayBorder;
    border-bottom: $grayBorder;
    border-radius: 24px;
    @include for-phone-only {
      border-radius: 12px;
    }
    .row {
      &:first-child .column:nth-child(even) {
        border-radius: 0px 0px 0px 0px;
        border-top: none;
        border-right: none;
        border-left: none;
      }
      &:first-child .column .mobile {
        @include for-phone-only {
          padding: 16px 0px 16px 0px;
        }
      }
      &:not(:first-child) .column {
        padding-top: 20px;
      }
      &:first-child {
        border-color: var(--GrayStrokeAndDisabled--opaque);
      }

      &:last-child {
        border-bottom: none;
        .column {
          border-bottom: none;
        }
      }
      .column {
        &:first-child {
          padding-left: 16px;
          align-items: center;
        }
        &:nth-child(even) {
          background: #edf6f2;
          border-right: none;
          border-left: none;
        }
        &:not(:first-child) {
          padding-left: 10px;
          padding-right: 10px;
        }

        flex: 1;
      }
    }
  }

  &.wills {
    max-width: 1160px;
    width: 100%;
    border: $lightGreenBorder;
    background: white;
    border-radius: 24px;

    .row {
      height: 96px;
      border-color: #dae7e6;
      border-bottom: $lightGreenBorder;
      &:first-child .column:nth-child(even) {
        border-radius: 0px 0px 0px 0px;
        border-top: none;
      }
      &:first-child .column .mobile {
        @include for-phone-and-tablet {
          padding: 16px 0px 16px 0px;
        }
      }
      &:not(:first-child) .column {
        padding-top: 20px;
      }
      &:first-child {
        border-color: #dae7e6;
      }

      &:last-child {
        border-bottom: none;
        .column {
          border-bottom: none;
        }
      }
      .column {
        &:first-child {
          padding-left: 16px;
          align-items: center;
        }
        &:nth-child(even) {
          background: #edf6f2;
          border-right: 2px solid #dae7e6;
          border-left: 2px solid #dae7e6;
          border-bottom: #dae7e6;
        }
        &:not(:first-child) {
          padding-left: 10px;
          padding-right: 10px;
        }

        flex: 1;
      }
    }
    @include for-phone-and-tablet {
      border-radius: 12px;

      .column {
        &:first-child {
          flex-basis: 15%;
        }
      }

      .xIcon {
        width: 20px !important;
      }

      .checkmark {
        width: 20px !important;
      }

      .row {
        height: 67px;

        &:not(:first-child) .column {
          padding: 16px 10px;
        }
      }
    }
  }
}

.text {
  font-family: NewTheinhardt;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  min-height: 41px;

  &.ethos {
    font-weight: 500;
    color: #0a9742;
  }

  @include for-laptop-and-up {
    font-size: 19px;
    line-height: 24px;
  }
}
