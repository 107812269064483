@import '~ethos-design-system/src/components/Media/Media.scss';

.chart {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: column;

    @include for-phone-only {
      padding-bottom: var(--Space-48);
    }

    &:first-child .column:first-child {
      border-top: solid 1px rgba(0, 0, 0, 0.1);
      @include for-tablet-and-up {
        border-top: none;
      }
    }

    @include for-tablet-and-up {
      flex-direction: row;
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);

      &:first-child {
        padding: 0 0 var(--Space-8);
        border-color: var(--GraySecondary--translucent);
      }

      &:not(:first-child) {
        padding: var(--Space-24) 0 20px;
      }
    }

    .column {
      display: flex;
      flex-basis: 33%;

      @include for-phone-only {
        &:first-child {
          border-bottom: solid 1px rgba(0, 0, 0, 0.1);
          padding-bottom: 12px;
        }

        &:not(:first-child) {
          padding-top: var(--Space-8);
        }
      }

      @include for-tablet-and-up {
        padding: 0;
        border-bottom: none;

        .icon {
          padding-top: var(--Space-4);
        }
      }

      &:not(:first-child) {
        justify-content: space-between;

        @include for-tablet-and-up {
          justify-content: center;
          text-align: center;
        }
      }
    }
  }

  .mobile {
    @include for-tablet-and-up {
      display: none;
    }
  }

  .desktop {
    display: none;

    @include for-tablet-and-up {
      display: block;

      &.row {
        display: flex;
      }
    }
  }
}
