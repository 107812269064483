@import '~ethos-design-system/src/components/Media/Media.scss';

.iulTitle {
  color: rgba(0, 0, 0, 0.85);
  font-family: Cambon;
  font-size: 43.5px;
  font-style: normal;
  font-weight: 600;
  line-height: 49px; /* 112.644% */

  @include for-phone-only {
    font-size: 32px;
    line-height: 36px;
  }
}
