@import '~ethos-design-system/src/components/Media/Media.scss';

.BannerText__Wrapper {
  @include for-phone-and-tablet {
    padding-top: var(--Space-32);
    padding-bottom: var(--Space-32);
    .LaptopAndUp {
      display: none !important;
    }
  }

  @include for-tablet-and-up {
    padding-top: var(--Space-64);
    padding-bottom: var(--Space-64);
  }

  @include for-laptop-and-up {
    .PhoneAndTablet {
      display: none !important;
    }
  }

  @include for-laptop-only {
    padding-left: var(--Space-64);
    padding-right: var(--Space-64);
  }

  @include for-desktop-only {
    padding-left: var(--Space-80);
    padding-right: var(--Space-80);
  }

  .BannerText__InnerColumn {
    margin: 0 auto;
    max-width: calc(100vw - 32px);
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    border: 1px solid #f4f4f4;
    background: #deebff;

    @include for-laptop-and-up {
      max-width: none;
    }
    .Module__Headings {
      flex-basis: unset;
      max-width: none;
      max-width: 475px;
      padding-top: 0;
      padding-bottom: var(--Space-24);
      @include for-laptop-and-up {
        padding-bottom: var(--Space-32);
      }
      .Heading {
        color: #000;
        font-family: Theinhardt;
        font-size: 28px;
        font-weight: 500;
        line-height: 40px; /* 142.857% */
        letter-spacing: -0.28px;
        max-width: 80%;
        margin: 0 auto;

        @include for-laptop-and-up {
          max-width: none;
        }
      }
      .SubHeading {
        color: #525252;
        /* Body L Reg */
        font-family: Theinhardt;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px; /* 144.444% */
        a {
          color: #525252;
        }
      }
    }
    .BannerText__CTA {
      padding: 0;

      button {
        min-height: 56px;
        padding: 16px 40px 12px;
        border-radius: 8px;
        border: 1px solid #f26d00;
        background: #f26d00;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        font-size: 24px;
        font-weight: 500;
        line-height: 34px; /* 141.667% */
        letter-spacing: -0.24px;
        display: flex;
        align-items: center;
        span {
          display: block;
          height: 29px;
          margin-left: var(--Space-12);
        }
      }
    }
  }
}

.BannerText__Inner {
  display: flex;
  flex-wrap: wrap;
  @include for-phone-and-tablet {
    flex-direction: column;
    padding: var(--Space-24) var(--Space-24) 0;
  }

  @include for-laptop-only {
    padding: 0 var(--Space-72);
  }

  @include for-desktop-only {
    padding: 0 var(--Space-64) 0;
  }
}

.BannerText__InnerDuckegg {
  background-color: var(--BrandDuckegg);
}

.BannerText__InnerButtercup {
  background-color: var(--BrandButtercup);
  color: var(--BrandSalamander);
}

.BannerText__InnerMoss {
  background-color: var(--BrandMoss);
  color: var(--BrandForest);
}

.BannerText__InnerWhite {
  background-color: var(--White);
}

.BannerText__InnerOcean {
  background-color: #deebff;
  padding: 32px 20px;
}

.Module__Headings {
  @include for-phone-and-tablet {
    max-width: 327px;
    width: 100%;
    padding-bottom: var(--Space-32);
    margin: 0 auto;
  }

  @include for-laptop-only {
    flex-basis: 480px;
    max-width: 54%; // 480 / 896
    padding: var(--Space-56) 0;
  }

  @include for-desktop-only {
    flex-basis: 640px;
    max-width: 60%; // 640 / 1280
    padding: var(--Space-64) 0 var(--Space-80);
  }
}

.BannerText__CTA {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;

  @include for-phone-and-tablet {
    justify-content: center;
    padding-bottom: var(--Space-40);

    // Hack: EDS generates CSS Modules like Button-module--Button--12345
    // so we cannot just do `.Button` here.
    [class^='Button-'] {
      display: flex;
      justify-content: center;
    }
    a {
      width: 100%;
      max-width: 327px;
    }
  }

  @include for-laptop-and-up {
    a {
      text-align: right;
    }
  }

  @include for-laptop-only {
    padding-left: var(--Space-56);
  }

  @include for-desktop-only {
    padding-left: var(--Space-64);
  }
}
