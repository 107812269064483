@import '~ethos-design-system/src/components/Media/Media.scss';

$overlaySize: 250px;
$halfOverlaySize: 125px;

$overlay: 644px;
$mobileOverlay: 60px;

.northstarTitle {
  font-family: 'Cambon';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 49px;
  color: #054742;
  @include for-phone-only {
    font-size: 32px;
    line-height: 36px;
  }
}
