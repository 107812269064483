@import '~ethos-design-system/src/components/Media/Media.scss';

.sectionContainer {
  margin: 0 60px;

  @include for-phone-and-tablet {
    margin: 0;
  }
}

.wrapper {
  @include for-phone-only {
    .blockCtaTabletAndUp {
      display: none;
    }
  }

  @include for-tablet-and-up {
    .blockCtaPhoneOnly {
      display: none;
    }
  }

  .intro {
    flex-basis: 100%;

    .headings {
      max-width: 560px;
      padding-top: var(--Space-32);

      @include for-tablet-and-laptop {
        padding-top: var(--Space-56);
      }

      @include for-desktop-only {
        padding-top: var(--Space-72);
      }
    }

    .centerHeadings {
      @extend .headings;

      @include for-tablet-and-up {
        margin: auto;
        text-align: center;
      }
    }
  }

  .chartContainer {
    padding-top: var(--Space-48);

    @include for-tablet-and-up {
      padding-bottom: 96px;
    }

    @include for-desktop-only {
      padding-bottom: 112px;
    }
  }

  .cta {
    justify-content: flex-start;
    display: inline-block;
  }

  .northstarSpacer {
    height: 8px;
    @include for-phone-only {
      height: 0px;
    }
  }

  &.rebranded {
    .cta {
      display: block;
      @include for-tablet-and-up {
        width: 307px;
        margin: 0 auto;
      }
    }
    .topCta {
      display: none;
    }
    .bottomCta button {
      width: 100%;
    }
    @include for-phone-only {
      .topCta {
        display: block;
      }
      .bottomCta {
        display: none;
      }
      padding-bottom: var(--Space-32);
    }
  }

  &.wills {
    border-radius: 24px;
    background: #f1f6f6;
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 80px;

    .chartContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 80px 0 100px 0;
      margin: 0;
    }
    .centerHeadings {
      padding: 100px 0 0 0;
    }

    @include for-phone-and-tablet {
      padding: 0 16px;
      border-radius: 0;

      .centerHeadings {
        padding: 48px 0 0 0;
      }
      .chartContainer {
        padding: 32px 0 48px 0;
      }
    }
  }

  &.northstar {
    .chartContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: var(--Space-24);
      padding-bottom: var(--Space-32);
    }
    .centerHeadings {
      @include for-laptop-and-up {
        padding-top: 100px;
      }
    }
    .cta {
      margin-top: 48px;
    }
    .topCta {
      display: none;
    }
    .cta button {
      display: block;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;

      width: 226px;
      height: 56px;

      border-radius: 8px;
      font-size: 17.5px;
      font-weight: 500;
      padding-top: 16px;
      padding-bottom: 16px;
    }
    @include for-phone-only {
      .centerHeadings {
        margin-right: 24px;
        margin-left: 24px;
        text-align: center;
        padding-top: 48px;
      }
      .chartContainer {
        padding-top: var(--Space-24);
        padding-bottom: var(--Space-48);
      }
      .topCta {
        display: none;
      }
      .cta {
        margin-top: var(--Space-48);
      }
    }
  }
}

.willsTitle {
  font-family: 'Cambon';
  font-weight: 600;
  color: black;
  width: max-content;
  margin: 0 auto;
  font-size: 60px;
  line-height: 80px;

  @include for-phone-and-tablet {
    font-size: 32px;
    line-height: 36px;
  }
}

.willsSubheading {
  color: #272727;
  text-align: center;
  font-family: NewTheinhardt;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -0.28px;
  margin: 0;
  margin-top: 8px;

  @include for-phone-and-tablet {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 0;
  }
}
