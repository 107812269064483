@import '~ethos-design-system/src/components/Media/Media.scss';

@mixin for-phone-and-laptop-mid {
  @media (max-width: $laptop-range-mid) {
    @content;
  }
}

@mixin for-tablet-and-laptop-mid {
  @media (min-width: $tablet-range-start) and (max-width: $laptop-range-mid) {
    @content;
  }
}

@mixin for-mid-tablet-and-up {
  @media (min-width: 820px) {
    @content;
  }
}

.title {
  color: var(--neutrals-night-100);
  font-family: Cambon;
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.64px;
  align-self: start;

  @include for-mid-tablet-and-up {
    font-size: 60px;
    line-height: 80px;
    letter-spacing: -1.2px;
    align-self: center;
  }
}

.subtitle {
  font-family: NewTheinhardt;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: start;

  @include for-mid-tablet-and-up {
    font-size: 28px;
    line-height: 40px;
    letter-spacing: -0.28px;
    text-align: center;
  }
}

.container {
  max-width: 1440px;
  padding: 120px 100px 48px;
  margin: 0 auto;
  width: 100%;

  @include for-mid-tablet-and-up {
    padding-bottom: 20px;
  }

  @include for-phone-and-laptop-mid {
    padding: 96px 80px 48px;
    width: 100%;
    border-radius: 0;
  }

  @media (min-width: 600px) and (max-width: 899px) {
    padding-left: 72px;
    padding-right: 72px;
  }

  @media (max-width: 599px) {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.flexWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 64px;

  @media (min-width: 820px) {
    gap: 24px;
  }

  @include for-mid-tablet-and-up {
    flex-direction: row;
  }
}

.inputWrapper {
  width: 100%;

  label {
    font-family: NewTheinhardt;
    font-size: 17.5px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.01em;
    margin-bottom: 8px;
  }

  input {
    font-size: 17.5px;
  }

  @include for-phone-and-tablet {
    input {
      font-size: 24px;
    }
  }

  @include for-mid-tablet-and-up {
    [class*='eds2-text-[--theme-error-emphasis'] {
      font-size: 20px;
    }
  }
}

.dropdownWrapper {
  label {
    font-family: NewTheinhardt;
    font-size: 24px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.01em;
    text-align: left;
    margin-bottom: 16px;
  }

  [class^='StyledReactSelect__control'] {
    padding: 32px 24px;
    height: 80px;
  }

  [class^='StyledReactSelect__placeholder'],
  [class^='StyledReactSelect__single-value'] {
    font-family: NewTheinhardt;
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  [class^='StyledReactSelect__option'] {
    font-family: NewTheinhardt;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: -0.01em;
  }

  @include for-mid-tablet-and-up {
    label {
      font-size: 28px;
    }

    [class^='StyledReactSelect__control'],
    [class^='StyledReactSelect__placeholder'],
    [class^='StyledReactSelect__single-value'] {
      font-size: 24px;
    }

    [class*='eds2-text-[--theme-error-emphasis'] {
      font-size: 20px;
    }

    [class^='StyledReactSelect__option'] {
      font-size: 20px;
    }
  }

  svg {
    width: 40px;
    height: 40px;
  }
}

.button {
  height: 50px;
  margin-top: 48px;
  margin-bottom: 16px;

  span {
    display: flex;
    align-items: center;
    font-size: 17.5px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.01em;
    text-align: center;
  }

  @include for-phone-and-laptop-mid {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.formTitle {
  font-family: Cambon;
  font-size: 28px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: -0.01em;
  margin-bottom: 24px !important;

  @include for-mid-tablet-and-up {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: -0.01em;
    margin-bottom: 48px !important;
  }
}

.closeIconContainer {
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0;
  color: var(--theme-fg-subtle);

  &.success {
    svg {
      position: absolute;
      top: 20px;
      right: 16px;
    }
  }
}

.modalTitle {
  font-family: NewTheinhardt;
  font-size: 28px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.01em;
  margin-bottom: 16px;
}

.modalContainer {
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 10px;
  overflow-y: auto;

  @media screen and (max-height: 680px) {
    max-height: 95vh;
  }

  .scrollable {
    height: 100%;

    @media screen and (max-height: 680px) {
      padding-bottom: 40px;
    }

    &.success {
      background-color: var(--BrandMoss);
      color: var(--BrandForest);
      border-radius: 5px;
      font-size: 17.5px;
      font-weight: 500;
      display: flex;
      align-items: baseline;
      flex-direction: row-reverse;

      @include for-phone-only {
        font-size: 24px;
      }
    }
  }
}
